<template>
  <div
    :class="[
      'vms-invoices-overview vd-padding-medium',
      isMobileScreen ? 'vms-invoices-overview__mobile' : '',
    ]"
  >
    <div v-if="invoiceOverviewLoading">
      <default-loader></default-loader>
    </div>
    <div v-else>
      <project-invoices-total-price />
      <project-invoices-credits></project-invoices-credits>
      <project-invoices-invoice-details
        v-if="!hideInvoiceDetails"
      ></project-invoices-invoice-details>
      <edit-job-modal
        v-if="showEditJobModal"
        :selected-edit-job="selectedJob"
      ></edit-job-modal>
    </div>
  </div>
</template>

<script>
import ProjectInvoicesTotalPrice from '@views/project/invoices/project-invoices-overview/total-price/project-invoices-overview-total-price'
import ProjectInvoicesCredits from '@views/project/invoices/project-invoices-overview/credits/project-invoices-overview-credits'
import ProjectInvoicesInvoiceDetails from '@views/project/invoices/project-invoices-overview/invoice-details/invoice-details'
import EditJobModal from '@views/project/invoices/components/project-invoices-edit-job-modal'
import DefaultLoader from '@components/loaders/default-loader'
import SweetAlert from '@plugins/sweet-alert'

import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    ProjectInvoicesTotalPrice,
    ProjectInvoicesCredits,
    ProjectInvoicesInvoiceDetails,
    EditJobModal,
    DefaultLoader,
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      showEditJobModal: 'project/invoice/showEditJobModal',
      invoiceOverviewLoading: 'project/invoice/invoiceOverviewLoading',
      selectedJob: 'project/invoice/selectedInvoiceJob',
      invoiceDetailChanged: 'project/invoice/invoiceDetailChanged',
      hideInvoiceDetails: 'project/invoice/hideInvoiceDetails',
      isMobileScreen: 'common/isMobileScreen',
    }),
  },

  beforeRouteLeave(to, from, next) {
    if (this.invoiceDetailChanged) {
      SweetAlert.fire({
        title: 'Confirm Navigation',
        html: `<br> You have unsaved changes. <br><br> Are you sure you want to leave this page?`,
        showCancelButton: true,
        confirmButtonText: 'Leave this page',
      }).then((result) => {
        if (result.isConfirmed) {
          next()
        }
      })
    } else {
      next()
    }
  },

  beforeMount() {
    this.getProjectInvoiceOverview({
      projectId: this.project.id,
    })
  },

  methods: {
    ...mapActions({
      getProjectInvoiceOverview: 'project/invoice/getProjectInvoiceOverview',
    }),
  },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vms-invoices-overview__invoice-terms"},[_c('div',{class:[
      'vd-padding-bottom-20',
      _vm.isMobileScreen ? '' : 'vd-padding-top-20' ]},[_c('div',{class:[
        _vm.isMobileScreen
          ? 'vd-margin-bottom-medium'
          : 'vms-invoices-overview__invoice-terms--left' ]},[_vm._v(" Send invoice to "),_c('span',{staticClass:"vd-red"},[_vm._v("*")]),_vm._v(": ")]),_c('div',{class:[
        _vm.isMobileScreen ? '' : 'vms-invoices-overview__invoice-terms--right' ]},[_c('div',{class:[
          'vms-invoices-overview__invoice-term-option',
          _vm.isMobileScreen
            ? 'vd-display-inline-block vd-margin-right-large'
            : '' ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.invoiceThirdParty),expression:"invoiceThirdParty"}],attrs:{"id":"invoiceRecipientClient","type":"radio","value":"client"},domProps:{"checked":_vm._q(_vm.invoiceThirdParty,"client")},on:{"change":function($event){_vm.invoiceThirdParty="client"}}}),_c('label',{staticClass:"vd-margin-left-small",attrs:{"for":"invoiceRecipientClient"}},[_vm._v(" Client ")])]),_c('div',{class:[
          'vms-invoices-overview__invoice-term-option',
          _vm.isMobileScreen ? 'vd-display-inline-block' : '' ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.invoiceThirdParty),expression:"invoiceThirdParty"}],attrs:{"id":"invoiceRecipientThirdParty","type":"radio","value":"third-party"},domProps:{"checked":_vm._q(_vm.invoiceThirdParty,"third-party")},on:{"change":function($event){_vm.invoiceThirdParty="third-party"}}}),_c('label',{staticClass:"vd-margin-left-small",attrs:{"for":"invoiceRecipientThirdParty"}},[_vm._v(" Third party ")])])])]),(_vm.isThirdPartySelected)?_c('div',{class:[
      'vms-invoices-overview__invoice-third-party vd-padding-bottom-20',
      _vm.isMobileScreen
        ? 'vd-padding-large vd-background-lightest-grey vd-border-radius-6'
        : '' ]},[_c('div',{class:[
        _vm.isMobileScreen
          ? ''
          : 'vms-invoices-overview__invoice-terms--left vd-padding-top-30' ]},[_vm._v(" Entity name "),_c('span',{staticClass:"vd-red"},[_vm._v("*")]),_vm._v(": ")]),_c('div',{class:[
        _vm.isMobileScreen
          ? 'vd-margin-bottom-large'
          : 'vms-invoices-overview__invoice-terms--right' ]},[_c('div',{class:[
          'vms-invoices-overview__invoice-terms--entity-wrapper',
          _vm.isMobileScreen
            ? 'vms-invoices-overview__invoice-terms--entity-wrapper__mobile'
            : '' ]},[_c('input-component',{attrs:{"type":"text","name":"entityName","value":_vm.entityNameValue,"container-custom-classes":[_vm.setInputValidationClass('entityName')]},on:{"formInput":function($event){return _vm.handleInputUpdate($event, 'entityName')}}})],1)]),_c('div',{class:[
        _vm.isMobileScreen
          ? ''
          : 'vms-invoices-overview__invoice-terms--left vd-padding-top-30' ]},[_vm._v(" Entity contact name "),_c('span',{staticClass:"vd-red"},[_vm._v("*")]),_vm._v(": ")]),_c('div',{class:[
        _vm.isMobileScreen
          ? 'vd-margin-bottom-large'
          : 'vms-invoices-overview__invoice-terms--right' ]},[_c('div',{class:[
          'vms-invoices-overview__invoice-terms--entity-wrapper',
          _vm.isMobileScreen
            ? 'vms-invoices-overview__invoice-terms--entity-wrapper__mobile'
            : '' ]},[_c('input-component',{attrs:{"type":"text","name":"entityContactName","value":_vm.entityContactNameValue,"container-custom-classes":[
            _vm.setInputValidationClass('entityContactName') ]},on:{"formInput":function($event){return _vm.handleInputUpdate($event, 'entityContactName')}}})],1)]),_c('div',{class:[
        _vm.isMobileScreen
          ? ''
          : 'vms-invoices-overview__invoice-terms--left vd-padding-top-30' ]},[_vm._v(" Entity contact email "),_c('span',{staticClass:"vd-red"},[_vm._v("*")]),_vm._v(": ")]),_c('div',{class:[
        _vm.isMobileScreen
          ? 'vd-margin-bottom-large'
          : 'vms-invoices-overview__invoice-terms--right' ]},[_c('div',{class:[
          'vms-invoices-overview__invoice-terms--entity-wrapper',
          _vm.isMobileScreen
            ? 'vms-invoices-overview__invoice-terms--entity-wrapper__mobile'
            : '' ]},[_c('input-component',{attrs:{"type":"text","name":"entityContactEmail","value":_vm.entityContactEmailValue,"container-custom-classes":[
            _vm.setInputValidationClass('entityContactEmail') ]},on:{"formInput":function($event){return _vm.handleInputUpdate($event, 'entityContactEmail')}}})],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
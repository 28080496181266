<template>
  <default-modal
    :class="['show', isMobileScreen ? 'vms-modal--fullscreen' : '']"
    modal-dialog-class="modal-md"
    content-class="vd-background-white"
  >
    <div slot="modal-header" class="modal-header">
      <div class="vd-h4 vd-light-blue">Edit Job</div>
      <div class="vms-modal__header--close close vd-black">
        <span v-show="!editJobLoading" @click="toggleEditJobShowModal">x</span>
      </div>
    </div>
    <div slot="modal-body" class="modal-body">
      <default-loader v-if="showLoader"></default-loader>
      <div v-else class="row vd-padding-medium">
        <div class="col-12">
          <div class="vd-margin-bottom-20">
            <input-component
              type="text"
              name="jobName"
              label="Job Name"
              :value="jobName"
              :required="true"
              :container-custom-classes="[setInputValidationClass('jobName')]"
              @formInput="handleInputUpdate($event, 'jobName')"
            ></input-component>
          </div>
        </div>
        <div v-if="isDisbursementJob" class="col-12">
          <div class="vms-invoices__disbursement-cost">
            <div
              v-for="productElement in disbursementProductElements"
              :key="productElement.id"
              :class="[
                'vms-invoices__disbursement-cost--product-elements vd-cursor-pointer vd-padding-top-extra-small vd-padding-right-medium vd-padding-bottom-extra-small vd-padding-left-medium vd-margin-bottom-10 vd-margin-right-10 vd-border-radius-50',
                selectedProductElementId === productElement.id
                  ? 'vd-background-light-blue vd-white'
                  : 'vd-background-lightest-grey',
              ]"
              @click="selectedProductElementId = productElement.id"
            >
              {{ productElement.product_element_type.name }}
            </div>
          </div>
        </div>
        <div class="col-12">
          <input-component
            v-model.number="selectedJobPrice"
            type="number"
            name="selectedJobPrice"
            label="Price"
            :required="true"
            prepend-text="$"
            :container-custom-classes="[
              setInputValidationClass('selectedJobPrice'),
            ]"
            @formInput="handleInputUpdate($event, 'selectedJobPrice')"
          ></input-component>
        </div>
      </div>
    </div>

    <div slot="modal-footer" class="modal-footer vd-margin-top-30">
      <div class="vd-text-align-right">
        <div
          class="vd-red vd-padding-top-10 vd-cursor-pointer vd-margin-right-extra-large vd-display-inline-block"
          @click="handleDeleteJobBtn"
        >
          Delete
        </div>
        <button
          class="vd-btn-small vd-btn-blue"
          :disabled="formSaveBtnDisabled"
          @click="handleEditJobBtn"
        >
          Save
        </button>
      </div>
    </div>
  </default-modal>
</template>

<script>
import DefaultModal from '@components/modal/default-modal'
import DefaultLoader from '@components/loaders/default-loader'
import FormMixin from '@mixins/forms-mixin'
import SweetAlert from '@plugins/sweet-alert'
import InputComponent from '@components/form/form-input'
import { mapActions, mapGetters } from 'vuex'
import {
  required,
  integer,
  minValue,
  requiredIf,
  minLength,
} from 'vuelidate/lib/validators'
import { isEqual } from 'lodash'

export default {
  components: {
    DefaultModal,
    DefaultLoader,
    InputComponent,
  },

  mixins: [FormMixin],

  props: {
    selectedEditJob: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      jobName: null,
      selectedJobPrice: null,
      selectedProductElementId: null,
      defaultPayload: {},
    }
  },

  computed: {
    ...mapGetters({
      editJobLoading: 'project/invoice/editJobLoading',
      addDisbursementCostLoading: 'project/invoice/addDisbursementCostLoading',
      project: 'project/projectDetails',
      disbursementProductElements:
        'project/invoice/disbursementProductElements',
      formSaveBtnDisabled: 'project/formSaveBtnDisabled',
      isMobileScreen: 'common/isMobileScreen',
    }),

    submitBtnDisabled() {
      return this.$v.$invalid || this.editJobLoading
    },

    isDisbursementJob() {
      return this.selectedEditJob.disbursement_type !== null
    },

    showLoader() {
      return this.editJobLoading || this.addDisbursementCostLoading
    },

    changedPayload() {
      return {
        jobName: this.jobName,
        selectedJobPrice: this.selectedJobPrice,
        selectedProductElementId: this.selectedProductElementId,
      }
    },
  },

  validations: {
    jobName: {
      required,
      minLength: minLength(3),
    },

    selectedJobPrice: {
      required,
      integer,
      minValue: minValue(1),
    },

    selectedProductElementId: {
      required: requiredIf(function () {
        return this.isDisbursementJob
      }),
    },
  },

  watch: {
    submitBtnDisabled(val) {
      this.updateFormSaveBtnDisabled(val)
    },

    changedPayload(val) {
      this.updateFormSaveBtnDisabled(
        isEqual(this.defaultPayload, val) || this.submitBtnDisabled
      )
    },
  },

  beforeMount() {
    this.jobName = this.selectedEditJob.name
    this.selectedJobPrice = this.selectedEditJob.price
    this.selectedProductElementId = this.selectedEditJob.disbursement_product_element_id
  },

  mounted() {
    if (this.isDisbursementJob) {
      this.getDisbursementProductElements({
        customIncludes: ['product_element_type'],
      })
    }

    this.initDefaultPayload()
  },

  methods: {
    ...mapActions({
      toggleEditJobShowModal: 'project/invoice/toggleEditJobShowModal',
      editJob: 'project/invoice/editJob',
      deleteJob: 'project/invoice/deleteJob',
      getDisbursementProductElements:
        'project/invoice/getDisbursementProductElements',
      updateFormSaveBtnDisabled: 'project/updateFormSaveBtnDisabled',
    }),

    handleEditJobBtn() {
      const payload = {
        project_id: this.project.id,
        order_job_id: this.selectedEditJob.id,
        price: this.selectedJobPrice,
        name: this.jobName,
        product_element_id: this.selectedProductElementId,
      }

      this.editJob(payload)
    },

    handleDeleteJobBtn() {
      SweetAlert.fire({
        title: 'Warning!',
        html: 'Are you sure you want to delete this job?',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            project_id: this.project.id,
            order_job_id: this.selectedEditJob.id,
          }

          this.deleteJob(payload)
        }
      })
    },

    initDefaultPayload() {
      this.defaultPayload = {
        jobName: this.jobName,
        selectedJobPrice: this.selectedJobPrice,
        selectedProductElementId: this.selectedProductElementId,
      }
    },
  },
}
</script>

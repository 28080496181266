var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'vms-invoices-overview__invoice-details',
    _vm.isMobileScreen ? 'vd-margin-bottom-70' : 'vd-margin-bottom-50' ]},[_c('div',{class:[
      'vms-invoices-overview__invoice-details-title vd-light-blue vd-h4 vd-padding-bottom-20 ',
      !_vm.isMobileScreen ? 'vd-border-light-grey' : '' ]},[_c('strong',[_vm._v("Invoice Details")])]),_c('div',{class:[
      'vms-invoices-overview__invoice-details-wrapper',
      _vm.isMobileScreen
        ? 'vd-background-white vd-border-width-1 vd-border-light-grey vd-border-radius-6 vd-box-shadow vd-padding-medium vd-margin-bottom-medium'
        : '' ]},[_c('payment-terms',{attrs:{"default-payment-term":_vm.defaultPaymentTerms},on:{"update:payment-terms":_vm.updatePaymentTerms}}),_c('invoice-terms',{attrs:{"default-invoicing-terms":_vm.defaultInvoicingTerms},on:{"update:invoice-terms":_vm.updateInvoiceTerms,"click:invoice-term-fortnightly":_vm.initFortnightlyOfficeAccountant}}),_c('invoice-recipient',{attrs:{"default-third-party-value":_vm.invoiceThirdPartyValue,"entity-name-value":_vm.entityNameValue,"entity-contact-name-value":_vm.entityContactNameValue,"entity-contact-email-value":_vm.entityContactEmailValue},on:{"update:invoice-third-party":_vm.updateThirdParty,"update:entity-name":_vm.updateEntityName,"update:entity-contact-name":_vm.updateEntityContactName,"update:entity-contact-email":_vm.updateEntityContactEmail}}),_c('invoice-contact-search',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isThirdPartySelected),expression:"!isThirdPartySelected"}],attrs:{"invoicing-terms":_vm.invoicingTerms || 0}}),_c('div',{staticClass:"vd-text-align-right vd-margin-top-30"},[_c('button',{staticClass:"vd-btn-medium vd-btn-blue",attrs:{"disabled":_vm.submitBtnDisabled},on:{"click":function($event){return _vm.handleInvoiceDetailsSubmit()}}},[_vm._v(" Save ")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div
    :class="[
      'vms-invoices-overview__invoice-details',
      isMobileScreen ? 'vd-margin-bottom-70' : 'vd-margin-bottom-50',
    ]"
  >
    <div
      :class="[
        'vms-invoices-overview__invoice-details-title vd-light-blue vd-h4 vd-padding-bottom-20 ',
        !isMobileScreen ? 'vd-border-light-grey' : '',
      ]"
    >
      <strong>Invoice Details</strong>
    </div>
    <div
      :class="[
        'vms-invoices-overview__invoice-details-wrapper',
        isMobileScreen
          ? 'vd-background-white vd-border-width-1 vd-border-light-grey vd-border-radius-6 vd-box-shadow vd-padding-medium vd-margin-bottom-medium'
          : '',
      ]"
    >
      <payment-terms
        :default-payment-term="defaultPaymentTerms"
        @update:payment-terms="updatePaymentTerms"
      ></payment-terms>

      <invoice-terms
        :default-invoicing-terms="defaultInvoicingTerms"
        @update:invoice-terms="updateInvoiceTerms"
        @click:invoice-term-fortnightly="initFortnightlyOfficeAccountant"
      ></invoice-terms>

      <invoice-recipient
        :default-third-party-value="invoiceThirdPartyValue"
        :entity-name-value="entityNameValue"
        :entity-contact-name-value="entityContactNameValue"
        :entity-contact-email-value="entityContactEmailValue"
        @update:invoice-third-party="updateThirdParty"
        @update:entity-name="updateEntityName"
        @update:entity-contact-name="updateEntityContactName"
        @update:entity-contact-email="updateEntityContactEmail"
      ></invoice-recipient>

      <invoice-contact-search
        v-show="!isThirdPartySelected"
        :invoicing-terms="invoicingTerms || 0"
      >
      </invoice-contact-search>

      <div class="vd-text-align-right vd-margin-top-30">
        <button
          :disabled="submitBtnDisabled"
          class="vd-btn-medium vd-btn-blue"
          @click="handleInvoiceDetailsSubmit()"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { required, email, requiredIf } from 'vuelidate/lib/validators'
import { isEmpty, isNull, cloneDeep, isEqual, forEach } from 'lodash'
import InvoiceContactSearch from '@views/project/invoices/project-invoices-overview/components/invoice-contact-search'
import FormMixin from '@mixins/forms-mixin'
import PaymentTerms from '@views/project/invoices/project-invoices-overview/invoice-details/invoice-details-payment-terms'
import InvoiceTerms from '@views/project/invoices/project-invoices-overview/invoice-details/invoice-details-invoice-terms'
import InvoiceRecipient from '@views/project/invoices/project-invoices-overview/invoice-details/invoice-details-recipient'

export default {
  components: {
    InvoiceContactSearch,
    PaymentTerms,
    InvoiceTerms,
    InvoiceRecipient,
  },

  mixins: [FormMixin],

  data() {
    return {
      paymentTerms: null,
      invoicingTerms: null,
      invoiceThirdParty: null,
      entityName: '',
      entityContactName: '',
      entityContactEmail: '',
      defaultPayload: {},
    }
  },

  validations: {
    paymentTerms: { required },
    invoicingTerms: { required },
    invoiceThirdParty: { required },
    entityName: {
      required: requiredIf(function () {
        return this.isThirdPartySelected
      }),
    },
    entityContactName: {
      required: requiredIf(function () {
        return this.isThirdPartySelected
      }),
    },
    entityContactEmail: {
      required: requiredIf(function () {
        return this.isThirdPartySelected
      }),
      email,
    },
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      invoiceOrderDetail: 'project/invoice/invoiceOrderDetail',
      invoiceContacts: 'project/invoice/invoiceContacts',
      invoiceDetailsLoading: 'project/invoice/invoiceDetailsLoading',
      isMobileScreen: 'common/isMobileScreen',
    }),

    isThirdPartySelected() {
      return this.invoiceThirdParty === 'third-party'
    },

    invoiceContactsValidation() {
      return (
        !this.isThirdPartySelected &&
        (isEmpty(this.invoiceContacts) || this.invoiceContacts.length > 2)
      )
    },

    submitBtnDisabled() {
      return (
        !this.invoiceDetailHasChanges ||
        this.$v.$invalid ||
        this.invoiceDetailsLoading ||
        this.invoiceContactsValidation
      )
    },

    defaultPaymentTerms() {
      return !isNull(this.invoiceOrderDetail.payment_term_id)
        ? this.invoiceOrderDetail.payment_term_id.toString()
        : null
    },

    defaultInvoicingTerms() {
      return !isNull(this.invoiceOrderDetail.order_invoicing_term_id)
        ? this.invoiceOrderDetail.order_invoicing_term_id
        : null
    },

    invoiceThirdPartyValue() {
      return this.invoiceOrderDetail.third_party_payment
        ? 'third-party'
        : 'client'
    },

    entityNameValue() {
      return this.invoiceOrderDetail.invoice_entity_name
        ? this.invoiceOrderDetail.invoice_entity_name
        : null
    },

    entityContactNameValue() {
      return this.invoiceOrderDetail.invoice_entity_contact_name
        ? this.invoiceOrderDetail.invoice_entity_contact_name
        : null
    },

    entityContactEmailValue() {
      return this.invoiceOrderDetail.invoice_entity_contact_email
        ? this.invoiceOrderDetail.invoice_entity_contact_email
        : null
    },

    // Computed property to check if value has changed.
    invoiceDetailHasChanges() {
      return (
        this.defaultPayload.payment_term_id !== this.paymentTerms ||
        this.defaultPayload.order_invoicing_term_id !== this.invoicingTerms ||
        this.defaultPayload.third_party_payment !== this.invoiceThirdParty ||
        this.defaultPayload.invoice_entity_name !== this.entityName ||
        this.defaultPayload.invoice_entity_contact_name !==
          this.entityContactName ||
        this.defaultPayload.invoice_entity_contact_email !==
          this.entityContactEmail ||
        !isEqual(this.defaultPayload.invoice_contacts, this.invoiceContacts)
      )
    },
  },

  watch: {
    invoiceDetailHasChanges(val) {
      this.updateInvoiceDetailChanged(val)
    },
  },

  mounted() {
    this.paymentTerms = this.defaultPaymentTerms
    this.invoicingTerms = this.defaultInvoicingTerms
    this.invoiceThirdParty = this.invoiceThirdPartyValue
    this.entityName = this.entityNameValue
    this.entityContactName = this.entityContactNameValue
    this.entityContactEmail = this.entityContactEmailValue

    this.initDefaultPayload()
    this.initDefaultOfficeAccountant()
  },

  methods: {
    ...mapActions({
      updateInvoiceContacts: 'project/invoice/updateInvoiceContacts',
      updateInvoiceDetails: 'project/invoice/updateInvoiceDetails',
      updateInvoiceDetailChanged: 'project/invoice/updateInvoiceDetailChanged',
    }),

    updatePaymentTerms(selectedPaymentTerm) {
      this.paymentTerms = selectedPaymentTerm
    },

    updateInvoiceTerms(selectedInvoiceTerm) {
      this.invoicingTerms = selectedInvoiceTerm
    },

    updateThirdParty(selectedThirdPartyType) {
      this.invoiceThirdParty = selectedThirdPartyType
    },

    updateEntityName(newEntityName) {
      this.entityName = newEntityName
    },

    updateEntityContactName(newEntityContactName) {
      this.entityContactName = newEntityContactName
    },

    updateEntityContactEmail(newEntityContactEmail) {
      this.entityContactEmail = newEntityContactEmail
    },

    /**
     * Create a snapshot of initial value for computed property to check if changes exist.
     */
    initDefaultPayload() {
      this.defaultPayload = {
        payment_term_id: this.paymentTerms,
        order_invoicing_term_id: this.invoicingTerms,
        third_party_payment: this.invoiceThirdParty,
        invoice_entity_name: this.entityName,
        invoice_entity_contact_name: this.entityContactName,
        invoice_entity_contact_email: this.entityContactEmail,
      }
    },

    initFortnightlyOfficeAccountant() {
      if (!isEmpty(this.invoiceOrderDetail.office_accountant)) {
        this.updateInvoiceContacts([
          {
            id: this.invoiceOrderDetail.office_accountant.id.toString(),
            full_name: this.invoiceOrderDetail.office_accountant.full_name,
            email: this.invoiceOrderDetail.office_accountant.email,
            is_office_accountant: true,
          },
        ])
      }
    },

    initDefaultOfficeAccountant() {
      const contactList = []

      if (!isEmpty(this.invoiceOrderDetail.invoice_contacts)) {
        forEach(this.invoiceOrderDetail.invoice_contacts, (contact) => {
          contactList.push({
            id: contact.id.toString(),
            full_name: contact.full_name,
            email: contact.email,
            is_office_accountant:
              this.invoiceOrderDetail.office_accountant &&
              this.invoiceOrderDetail.office_accountant.id === contact.id,
          })
        })
      }

      this.updateInvoiceContacts(contactList)
      this.defaultPayload['invoice_contacts'] = cloneDeep(this.invoiceContacts)
    },

    handleInvoiceDetailsSubmit() {
      const payload = {
        project_id: this.project.id,
        payment_term_id: this.paymentTerms,
        order_invoicing_term_id: this.invoicingTerms,
        third_party_payment: this.isThirdPartySelected ? 1 : 0,
        invoice_entity_name: this.entityName,
        invoice_entity_contact_name: this.entityContactName,
        invoice_entity_contact_email: this.entityContactEmail,
        invoice_contacts: this.invoiceContacts,
      }

      // Reset default payload with newest data.
      this.initDefaultPayload()
      this.defaultPayload['invoice_contacts'] = cloneDeep(this.invoiceContacts)

      this.updateInvoiceDetails(payload)
      this.updateInvoiceDetailChanged(false)
    },
  },
}
</script>

<style lang="scss">
@import '@styles/components/forms/select';
</style>

<template>
  <div class="vd-padding-bottom-10">
    <div
      v-for="contact in invoiceContacts"
      :key="contact.id"
      :class="[
        'vms-invoices-overview__invoice-terms--selected-contact vd-margin-bottom-10 vd-position-relative',
        isMobileScreen
          ? 'vms-invoices-overview__invoice-terms--selected-contact__mobile vd-display-inline-block vd-padding-top-extra-small vd-padding-bottom-extra-small vd-padding-right-extra-large vd-padding-left-medium vd-background-lightest-grey vd-border-radius-50'
          : 'vd-border-light-grey vd-padding-right-40 vd-padding-bottom-10',
      ]"
    >
      <div v-if="!isMobileScreen" class="vd-h5 vd-dark-grey">
        {{ contact.full_name }}
        <span class="vd-blue">
          <strong>{{ contactLabel(contact) }}</strong>
        </span>
      </div>
      <div class="vd-h4 vd-black">{{ contact.email }}</div>
      <div
        v-if="
          !(
            contact.is_office_accountant &&
            currentInvoicingTerm ===
              vmsConstants.orderInvoiceTerms.ORDER_INVOICING_TERM_ID_FORTNIGHTLY
          )
        "
        :class="[
          'vms-invoices-overview__invoice-terms--delete-contact vd-icon-close-round',
          isMobileScreen ? 'vd-margin-right-extra-small' : '',
        ]"
        @click="handleRemoveContact(contact)"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VmsConstants from '@configs/vms-constants'

export default {
  props: {
    currentInvoicingTerm: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      vmsConstants: VmsConstants,
    }
  },

  computed: {
    ...mapGetters({
      invoiceContacts: 'project/invoice/invoiceContacts',
      isMobileScreen: 'common/isMobileScreen',
    }),
  },

  methods: {
    ...mapActions({
      removeInvoiceContact: 'project/invoice/removeInvoiceContact',
    }),

    handleRemoveContact(contact) {
      this.removeInvoiceContact({
        id: contact.id,
      })
    },

    contactLabel(contact) {
      return contact.is_office_accountant
        ? '(Accountant)'
        : this.invoiceContacts.length > 1
        ? '(Secondary)'
        : ''
    },
  },
}
</script>

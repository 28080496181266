<template>
  <div
    class="vms-modal modal fade"
    :class="modalClass"
    @click.self="$emit('click:backdrop')"
  >
    <div
      class="vms-modal__dialog modal-dialog modal-dialog-centered"
      :class="modalDialogClass"
    >
      <div class="vms-modal__content modal-content" :class="contentClass">
        <slot name="modal-header"></slot>
        <slot name="modal-body"></slot>
        <slot name="modal-footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modalClass: {
      type: String,
      required: false,
      default: '',
    },

    modalDialogClass: {
      type: [String, Array],
      required: false,
      default: 'modal-lg',
    },

    contentClass: {
      type: String,
      required: false,
      default: '',
    },
  },

  mounted() {
    // prevent double scrollbar
    document.body.classList.add('modal-open')
  },

  beforeDestroy() {
    // remove custom class in body
    document.body.classList.remove('modal-open')
  },
}
</script>

<style lang="scss">
@import '@styles/components/modal';
</style>

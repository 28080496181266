var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vd-padding-bottom-10"},_vm._l((_vm.invoiceContacts),function(contact){return _c('div',{key:contact.id,class:[
      'vms-invoices-overview__invoice-terms--selected-contact vd-margin-bottom-10 vd-position-relative',
      _vm.isMobileScreen
        ? 'vms-invoices-overview__invoice-terms--selected-contact__mobile vd-display-inline-block vd-padding-top-extra-small vd-padding-bottom-extra-small vd-padding-right-extra-large vd-padding-left-medium vd-background-lightest-grey vd-border-radius-50'
        : 'vd-border-light-grey vd-padding-right-40 vd-padding-bottom-10' ]},[(!_vm.isMobileScreen)?_c('div',{staticClass:"vd-h5 vd-dark-grey"},[_vm._v(" "+_vm._s(contact.full_name)+" "),_c('span',{staticClass:"vd-blue"},[_c('strong',[_vm._v(_vm._s(_vm.contactLabel(contact)))])])]):_vm._e(),_c('div',{staticClass:"vd-h4 vd-black"},[_vm._v(_vm._s(contact.email))]),(
        !(
          contact.is_office_accountant &&
          _vm.currentInvoicingTerm ===
            _vm.vmsConstants.orderInvoiceTerms.ORDER_INVOICING_TERM_ID_FORTNIGHTLY
        )
      )?_c('div',{class:[
        'vms-invoices-overview__invoice-terms--delete-contact vd-icon-close-round',
        _vm.isMobileScreen ? 'vd-margin-right-extra-small' : '' ],on:{"click":function($event){return _vm.handleRemoveContact(contact)}}}):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }
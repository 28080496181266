<template>
  <div class="vms-invoices-overview__total-project vd-margin-bottom-50">
    <div
      class="vms-invoices-overview__total-title vd-light-blue vd-h4 vd-padding-bottom-20 vd-border-light-grey"
    >
      <strong>Total project price</strong>
    </div>
    <div
      v-if="invoiceJobs.length === 0"
      class="vms-invoices-overview__empty vd-text-align-center vd-padding-top-20"
    >
      <img src="/images/empty-states/empty-price-list.svg" />

      <p class="vd-padding-top-20">
        This project has no price! Use the + button to add details
      </p>
    </div>
    <div v-else class="vms-invoices-overview__jobs">
      <div
        v-for="(job, index) in invoiceJobs"
        :key="index"
        class="vms-invoices-overview__job vd-padding-bottom-20 vd-padding-top-20 vd-border-light-grey"
      >
        <div class="vms-invoices-overview__job--left">
          {{ job.name }}
          <span
            v-if="job.disbursement_type"
            class="vd-margin-left-10 vd-padding-top-extra-small vd-padding-right-medium vd-padding-bottom-extra-small vd-padding-left-medium vd-border-radius-50 vd-background-light-blue vd-white"
          >
            {{ job.disbursement_type }}
          </span>
        </div>
        <div class="vms-invoices-overview__job--right">
          {{ job.price | moneyFormat }}
          <span
            v-if="!canEditInvoiceJob(job.order)"
            :id="`editInvoiceJob-${job.id}Span`"
            @click="handleEditJobBtn(job.id)"
          >
            <div
              class="vms-invoices-overview__edit-price vd-margin-left-extra-small vd-icon-pencil-circle"
            ></div>
          </span>
        </div>
      </div>
      <div
        :class="[
          'vms-invoices-overview__job vms-invoices-overview__job--total vd-padding-bottom-20 vd-padding-top-20 vd-border-light-grey',
          isMediumScreen ? 'vms-invoices-overview__job--total__small' : '',
        ]"
      >
        <div class="vms-invoices-overview__job--left">
          <strong>Total (excl. GST)</strong>
        </div>
        <div class="vms-invoices-overview__job--right">
          <strong>{{ project.total_price | moneyFormat }}</strong>
        </div>
      </div>
      <div
        :class="[
          'vms-invoices-overview__job vms-invoices-overview__job--total vd-padding-bottom-20 vd-padding-top-20 vd-border-light-grey',
          isMediumScreen ? 'vms-invoices-overview__job--total__small' : '',
        ]"
      >
        <div class="vms-invoices-overview__job--left">Paid with credits</div>
        <div class="vms-invoices-overview__job--right">
          {{ invoicePaidWithCredits }}
        </div>
      </div>
      <div
        :class="[
          'vms-invoices-overview__job vms-invoices-overview__job--total vd-padding-bottom-20 vd-padding-top-20 vd-border-light-grey',
          isMediumScreen ? 'vms-invoices-overview__job--total__small' : '',
        ]"
      >
        <div class="vms-invoices-overview__job--left">Total invoiced</div>
        <div class="vms-invoices-overview__job--right">
          {{ invoiceTotalInvoiced | moneyFormat }}
        </div>
      </div>
      <div
        :class="[
          'vms-invoices-overview__job vms-invoices-overview__job--total vd-padding-bottom-20 vd-padding-top-20 vd-border-light-grey',
          isMediumScreen ? 'vms-invoices-overview__job--total__small' : '',
        ]"
      >
        <div class="vms-invoices-overview__job--left">
          <strong>Amount remaining to be invoiced</strong>
        </div>
        <div class="vms-invoices-overview__job--right vd-red">
          <strong>{{ invoiceRemainingToBeInvoiced | moneyFormat }}</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FiltersMixin from '@mixins/filters-mixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [FiltersMixin],
  props: {
    canEditInvoiceJob: {
      type: Function,
      required: true,
    },

    handleEditJobBtn: {
      type: Function,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      invoiceJobs: 'project/invoice/invoiceJobs',
      invoicePaidWithCredits: 'project/invoice/invoicePaidWithCredits',
      invoiceTotalInvoiced: 'project/invoice/invoiceTotalInvoiced',
      invoiceRemainingToBeInvoiced:
        'project/invoice/invoiceRemainingToBeInvoiced',
      isMediumScreen: 'common/isMediumScreen',
    }),
  },
}
</script>

<template>
  <div
    :class="[
      'vms-invoices-overview__invoice-terms',
      isMobileScreen ? '' : 'vd-padding-top-10 vd-border-light-grey',
    ]"
  >
    <div
      :class="[
        isMobileScreen ? '' : 'vms-invoices-overview__invoice-terms--left',
      ]"
    >
      <div class="vd-padding-bottom-extra-small">
        Invoice contact <span class="vd-red">*</span>:
      </div>
      <div
        v-if="
          invoicingTerms !=
          vmsConstants.orderInvoiceTerms.ORDER_INVOICING_TERM_ID_FORTNIGHTLY
        "
        class="vd-h5 vd-dark-grey vd-padding-right-20 vd-padding-bottom-20"
      >
        Maximum 2 contacts (Accountant and secondary email CC)
      </div>
    </div>
    <div
      :class="[
        isMobileScreen ? '' : 'vms-invoices-overview__invoice-terms--right',
      ]"
    >
      <div
        v-if="
          invoicingTerms !=
          vmsConstants.orderInvoiceTerms.ORDER_INVOICING_TERM_ID_FORTNIGHTLY
        "
        :class="[
          'vd-padding-bottom-20',
          isMobileScreen ? 'vd-display-block' : 'vd-display-inline-block',
        ]"
      >
        <ais-instant-search
          v-click-outside="closeSearchResults"
          index-name="user_offices_index"
          :search-client="searchClient"
        >
          <ais-clear-refinements :included-attributes="['query']">
            <clear-results
              slot-scope="{ refine }"
              @reset:results="refine()"
            ></clear-results>
          </ais-clear-refinements>
          <ais-configure :filters="filters" />
          <ais-search-box>
            <div
              slot-scope="{ currentRefinement, refine }"
              @click="isSearchOnFocus = true"
            >
              <search-box
                :current-refinement="currentRefinement"
                :is-search-on-focus="isSearchOnFocus"
                @update:current-refinement="refine($event.currentTarget.value)"
              ></search-box>
            </div>
          </ais-search-box>
          <ais-state-results>
            <div slot-scope="{ query, hits }" class="vd-position-relative">
              <no-results
                v-if="!hits.length && (isSearchOnFocus || query.length > 0)"
                :query="query"
              ></no-results>
              <ais-hits
                v-else-if="
                  (isSearchOnFocus || query.length > 0) && hits.length > 0
                "
              >
                <result-hits
                  slot-scope="{ items }"
                  :items="items"
                  @update:search-off-focus="closeSearchResults"
                ></result-hits>
              </ais-hits>
            </div>
          </ais-state-results>
        </ais-instant-search>
      </div>
      <selected-invoice-contacts
        :current-invoicing-term="invoicingTerms || 0"
      ></selected-invoice-contacts>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VmsConstants from '@configs/vms-constants'
import NoResults from '@views/project/invoices/project-invoices-overview/components/no-results'
import ResultHits from '@views/project/invoices/project-invoices-overview/components/result-hits'
import SearchBox from '@views/project/invoices/project-invoices-overview/components/search-box'
import ClearResults from '@views/project/invoices/project-invoices-overview/components/clear-results'
import SelectedInvoiceContacts from '@views/project/invoices/project-invoices-overview/components/selected-invoice-contacts'
import algoliaSearch from '@plugins/algolia'
import ClickOutside from 'vue-click-outside'

import {
  AisInstantSearch,
  AisSearchBox,
  AisHits,
  AisStateResults,
  AisConfigure,
  AisClearRefinements,
} from 'vue-instantsearch'

export default {
  components: {
    NoResults,
    ResultHits,
    SearchBox,
    ClearResults,
    SelectedInvoiceContacts,
    AisInstantSearch,
    AisSearchBox,
    AisHits,
    AisStateResults,
    AisConfigure,
    AisClearRefinements,
  },

  directives: {
    ClickOutside,
  },

  props: {
    invoicingTerms: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      vmsConstants: VmsConstants,
      searchClient: algoliaSearch,
      isSearchOnFocus: false,
    }
  },

  computed: {
    ...mapGetters({
      invoiceOrderDetail: 'project/invoice/invoiceOrderDetail',
      isMobileScreen: 'common/isMobileScreen',
    }),

    filters() {
      return `offices.id:${this.invoiceOrderDetail.office_id}`
    },
  },

  methods: {
    closeSearchResults() {
      this.isSearchOnFocus = false
    },
  },
}
</script>

<template>
  <div
    :class="[
      'vms-invoices-overview__invoice-terms vd-padding-bottom-20',
      isMobileScreen ? '' : 'vd-padding-top-20 vd-border-light-grey',
    ]"
  >
    <div
      :class="[
        isMobileScreen
          ? 'vd-margin-bottom-medium'
          : 'vms-invoices-overview__invoice-terms--left',
      ]"
    >
      Invoice terms <span class="vd-red">*</span>:
    </div>

    <div
      :class="[
        isMobileScreen ? '' : 'vms-invoices-overview__invoice-terms--right',
      ]"
    >
      <div
        v-if="
          project.office.client_term_id ===
          vmsConstants.clientTerms.OFFICE_CLIENT_TERM_FORTNIGHTLY
        "
        id="invoiceTermFortnightlyDiv"
        class="vms-invoices-overview__invoice-term-option"
        @click="handleInvoiceFortnightlyClick"
      >
        <input
          id="invoiceTermFortnightly"
          v-model="invoicingTerms"
          type="radio"
          :value="
            vmsConstants.orderInvoiceTerms.ORDER_INVOICING_TERM_ID_FORTNIGHTLY
          "
        />
        <label for="invoiceTermFortnightly" class="vd-margin-left-small">
          Fortnightly invoicing
        </label>
      </div>
      <div class="vms-invoices-overview__invoice-term-option">
        <input
          id="invoiceTerm100PercentOnOrderSubmission"
          v-model="invoicingTerms"
          type="radio"
          :value="
            vmsConstants.orderInvoiceTerms
              .ORDER_INVOICING_TERM_ID_100_PERCENT_ON_ORDER_SUBMISSION
          "
        />
        <label
          for="invoiceTerm100PercentOnOrderSubmission"
          class="vd-margin-left-small"
        >
          100% on order submission
        </label>
      </div>
      <div
        v-if="!creditApplied"
        class="vms-invoices-overview__invoice-term-option"
      >
        <input
          id="invoiceTerm50PercentUpfrontAndCompletion"
          v-model="invoicingTerms"
          type="radio"
          :value="
            vmsConstants.orderInvoiceTerms
              .ORDER_INVOICING_TERM_ID_50_PERCENT_UPFRONT_AND_COMPLETION
          "
        />
        <label
          for="invoiceTerm50PercentUpfrontAndCompletion"
          class="vd-margin-left-small"
        >
          50% upfront 50% completion
        </label>
      </div>
      <div class="vms-invoices-overview__invoice-term-option">
        <input
          id="invoiceTermAfterKeyDeliverable"
          v-model="invoicingTerms"
          type="radio"
          :value="
            vmsConstants.orderInvoiceTerms
              .ORDER_INVOICING_TERM_ID_AFTER_KEY_DELIVERABLE
          "
        />
        <label
          for="invoiceTermAfterKeyDeliverable"
          class="vd-margin-left-small"
        >
          After key deliverable
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { find, isNil } from 'lodash'
import VMSConstants from '@configs/vms-constants'

export default {
  props: {
    defaultInvoicingTerms: {
      type: Number,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      vmsConstants: VMSConstants,
      invoicingTerms: this.defaultInvoicingTerms,
    }
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      creditList: 'project/invoice/creditList',
      isMobileScreen: 'common/isMobileScreen',
    }),

    creditApplied() {
      return !isNil(
        find(this.creditList, (credit) => {
          return credit.total_used > 0
        })
      )
    },
  },

  watch: {
    invoicingTerms(val) {
      this.$emit('update:invoice-terms', val)
    },
  },

  methods: {
    handleInvoiceFortnightlyClick() {
      this.$emit('click:invoice-term-fortnightly')
    },
  },
}
</script>

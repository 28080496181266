<template>
  <div
    class="vd-background-white vd-border-width-1 vd-border-light-grey vd-border-radius-6 vd-box-shadow vd-padding-medium vd-margin-bottom-medium"
  >
    <div v-for="(credit, index) in creditList" :key="index">
      <div class="vd-margin-bottom-medium">
        <div>
          <span class="vd-dark-grey vd-margin-right-small">ID</span>
          <span class="vd-light-blue">#{{ credit.id }}</span>
        </div>

        <div>{{ credit.name }}</div>
      </div>

      <div class="row no-gutters">
        <div class="col-6">
          <div class="vd-dark-grey">Available</div>
          <div>{{ credit.balance }}</div>
        </div>
        <div class="col-6 vd-text-align-right">
          <div
            v-if="originalTotalCreditUsed"
            class="vms-invoices-overview__credits-input--mobile vd-background-lightest-grey vd-padding-small vd-border-radius-6 vd-border-light-grey vd-border-width-1 vd-text-align-right vd-display-inline-block"
          >
            {{ credit.total_used }}
          </div>
          <input
            v-else
            v-model.number="getCreditTotalUsed(credit.id).total_used"
            type="number"
            min="0"
            class="vms-invoices-overview__credits-input--mobile vd-background-white vd-padding-small vd-border-radius-6 vd-border-light-grey vd-border-width-1 vd-text-align-right"
            :disabled="isExpired(credit.credit_status_id)"
            @blur="handleBlur(credit.id)"
            @focus="handleFocus(credit.id)"
          />
        </div>
      </div>

      <hr class="vd-border-grey" />
    </div>

    <div class="vd-margin-bottom-medium">
      <div class="row no-gutters">
        <div class="col-6">
          <strong>Total amound left to be paid</strong>
        </div>

        <div class="col-6 vd-text-align-right">
          {{ totalAmountToBePaid | moneyFormat }}
        </div>
      </div>
    </div>

    <hr class="vd-border-grey" />

    <div class="vd-text-align-right">
      <p v-if="hasExpiredCredit" class="vd-red vd-text-align-left vd-h5">
        One or more of the credit packs have expired,<br />
        to change the credits used please contact Accounts for assistance.
      </p>
      <template v-else>
        <button
          v-if="originalTotalCreditUsed"
          id="clearInvoiceCreditsButton"
          class="vd-btn-medium vd-btn-white"
          @click="handleClearBtn"
        >
          Clear
        </button>
        <button
          v-else
          id="saveInvoiceCreditsButton"
          class="vd-btn-medium vd-btn-blue"
          :disabled="isSaveBtnDisabled"
          @click="handleSaveBtn"
        >
          Save
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  forEach,
  find,
  differenceWith,
  isEqual,
  isEmpty,
  orderBy,
  some,
  map,
  filter,
} from 'lodash'
import { required, minValue, numeric } from 'vuelidate/lib/validators'
import VmsConstants from '@configs/vms-constants'
import FiltersMixin from '@mixins/filters-mixin'
import FormMixin from '@mixins/forms-mixin'
import SweetAlert from '@plugins/sweet-alert'

export default {
  mixins: [FiltersMixin, FormMixin],

  data() {
    return {
      creditListData: [],
      originalCreditListData: [],
    }
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      creditList: 'project/invoice/creditList',
      invoiceRemainingToBeInvoiced:
        'project/invoice/invoiceRemainingToBeInvoiced',
    }),

    /**
     * Get an array of credit list object that was updated.
     * @returns {array}
     */
    updatedCreditListData() {
      let creditDifference = differenceWith(
        this.creditListData,
        this.originalCreditListData,
        isEqual
      )

      return orderBy(creditDifference, 'id')
    },

    isSaveBtnDisabled() {
      return (
        this.$v.$invalid ||
        isEmpty(this.updatedCreditListData) ||
        this.totalAmountToBePaid < 0 ||
        this.isCreditLimitExceeded
      )
    },

    originalTotalCreditUsed() {
      let total = 0

      forEach(this.originalCreditListData, (creditData) => {
        if (creditData.total_used !== '') {
          total += creditData.total_used
        }
      })

      return total
    },

    updatedTotalCreditUsed() {
      let total = 0

      forEach(this.creditListData, (creditData) => {
        if (creditData.total_used !== '') {
          total += creditData.total_used
        }
      })

      return total
    },

    totalAmountToBePaid() {
      return (
        this.invoiceRemainingToBeInvoiced -
        (this.updatedTotalCreditUsed - this.originalTotalCreditUsed)
      )
    },

    /**
     * Check if the credit applied exceed the available credits.
     * Available credits will include credits that is already applied on page load.
     *
     * @returns {boolean}
     */
    isCreditLimitExceeded() {
      let limitExceeded = false

      forEach(this.creditListData, (creditList) => {
        const availableCredit = find(this.creditList, { id: creditList.id })
        const originalUsedCredit = find(this.originalCreditListData, {
          id: creditList.id,
        })

        limitExceeded =
          limitExceeded ||
          creditList.total_used >
            availableCredit.balance + originalUsedCredit.total_used
      })

      return limitExceeded
    },

    hasExpiredCredit() {
      return some(this.creditList, (credit) => {
        return (
          credit.credit_status_id ===
          VmsConstants.creditStatuses.CREDIT_STATUS_ID_EXPIRED
        )
      })
    },
  },

  validations: {
    creditListData: {
      $each: {
        total_used: { required, numeric, minValue: minValue(0) },
      },
    },
  },

  beforeMount() {
    this.creditListData = this.getCreditListData()
    this.originalCreditListData = this.getCreditListData()
  },

  methods: {
    ...mapActions({
      updateProjectInvoiceCredits:
        'project/invoice/updateProjectInvoiceCredits',
    }),

    isExpired(creditStatusId) {
      return (
        creditStatusId === VmsConstants.creditStatuses.CREDIT_STATUS_ID_EXPIRED
      )
    },

    getCreditTotalUsed(id) {
      return find(this.creditListData, { id: id })
    },

    getCreditListData() {
      const creditListData = []

      forEach(this.creditList, (credit) => {
        creditListData.push({
          id: credit.id,
          total_used: credit.total_used,
        })
      })

      return creditListData
    },

    handleFocus(id) {
      const credit = find(this.creditListData, { id: id })

      credit.total_used = credit.total_used === 0 ? '' : credit.total_used
    },

    handleBlur(id) {
      const credit = find(this.creditListData, { id: id })

      credit.total_used = credit.total_used === '' ? 0 : credit.total_used
    },

    handleSaveBtn() {
      const payload = {
        project_id: this.project.id,
        payload: {
          credit_used: this.updatedCreditListData,
        },
      }

      this.updateProjectInvoiceCredits(payload)
    },

    handleClearBtn() {
      SweetAlert.fire({
        title: 'Are you sure?',
        html:
          'This will clear all credit used entries. If you do not re-apply any credits, an invoice may be sent.',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        heightAuto: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          // get all credits that are not 0 then reset total used to 0
          const creditUsed = map(
            filter(this.originalCreditListData, (credit) => {
              return credit.total_used !== 0
            }),
            (credit) => {
              return {
                id: credit.id,
                total_used: 0,
              }
            }
          )
          const payload = {
            project_id: this.project.id,
            payload: {
              credit_used: creditUsed,
            },
          }

          this.updateProjectInvoiceCredits(payload)
        }
      })
    },
  },
}
</script>

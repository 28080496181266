var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:[
      'vms-invoices-overview__accountant-contact vd-border-light-grey vd-padding-extra-small vd-border-radius-6',
      _vm.isMobileScreen
        ? 'vms-invoices-overview__accountant-contact--mobile vd-border-width-1'
        : '',
      _vm.isSearchOnFocus || _vm.hasValueInSearch
        ? 'vms-invoices-overview__accountant-contact--filled'
        : '' ]},[_c('div',{staticClass:"vd-icon-search vms-invoices-overview__search-icon vd-inline-block vd-margin-left-extra-small",attrs:{"id":"searchContactIconForInvoiceInput"},on:{"click":_vm.handleSearchIconClick}}),_c('input',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleBlur),expression:"handleBlur"}],ref:"searchContactForInvoiceInput",staticClass:"vms-invoices-overview__contact-search vd-inline-block vd-border-transparent vd-margin-0 vd-padding-left-10 vd-padding-top-0 vd-padding-bottom-0 vd-padding-right-0 vd-border-0",attrs:{"id":"searchContactForInvoiceInput"},domProps:{"value":_vm.currentRefinement},on:{"input":function($event){return _vm.$emit('update:current-refinement', $event)},"focus":function($event){return _vm.$emit('update:search-on-focus')}}})])])}
var staticRenderFns = []

export { render, staticRenderFns }
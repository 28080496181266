var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'vms-invoices-overview__invoice-terms',
    _vm.isMobileScreen ? '' : 'vd-padding-top-10 vd-border-light-grey' ]},[_c('div',{class:[
      _vm.isMobileScreen ? '' : 'vms-invoices-overview__invoice-terms--left' ]},[_vm._m(0),(
        _vm.invoicingTerms !=
        _vm.vmsConstants.orderInvoiceTerms.ORDER_INVOICING_TERM_ID_FORTNIGHTLY
      )?_c('div',{staticClass:"vd-h5 vd-dark-grey vd-padding-right-20 vd-padding-bottom-20"},[_vm._v(" Maximum 2 contacts (Accountant and secondary email CC) ")]):_vm._e()]),_c('div',{class:[
      _vm.isMobileScreen ? '' : 'vms-invoices-overview__invoice-terms--right' ]},[(
        _vm.invoicingTerms !=
        _vm.vmsConstants.orderInvoiceTerms.ORDER_INVOICING_TERM_ID_FORTNIGHTLY
      )?_c('div',{class:[
        'vd-padding-bottom-20',
        _vm.isMobileScreen ? 'vd-display-block' : 'vd-display-inline-block' ]},[_c('ais-instant-search',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeSearchResults),expression:"closeSearchResults"}],attrs:{"index-name":"user_offices_index","search-client":_vm.searchClient}},[_c('ais-clear-refinements',{attrs:{"included-attributes":['query']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var refine = ref.refine;
return _c('clear-results',{on:{"reset:results":function($event){return refine()}}})}}],null,false,3233518399)}),_c('ais-configure',{attrs:{"filters":_vm.filters}}),_c('ais-search-box',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var currentRefinement = ref.currentRefinement;
      var refine = ref.refine;
return _c('div',{on:{"click":function($event){_vm.isSearchOnFocus = true}}},[_c('search-box',{attrs:{"current-refinement":currentRefinement,"is-search-on-focus":_vm.isSearchOnFocus},on:{"update:current-refinement":function($event){return refine($event.currentTarget.value)}}})],1)}}],null,false,2673016690)}),_c('ais-state-results',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var query = ref.query;
      var hits = ref.hits;
return _c('div',{staticClass:"vd-position-relative"},[(!hits.length && (_vm.isSearchOnFocus || query.length > 0))?_c('no-results',{attrs:{"query":query}}):(
                (_vm.isSearchOnFocus || query.length > 0) && hits.length > 0
              )?_c('ais-hits',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var items = ref.items;
return _c('result-hits',{attrs:{"items":items},on:{"update:search-off-focus":_vm.closeSearchResults}})}}],null,true)}):_vm._e()],1)}}],null,false,1308883712)})],1)],1):_vm._e(),_c('selected-invoice-contacts',{attrs:{"current-invoicing-term":_vm.invoicingTerms || 0}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vd-padding-bottom-extra-small"},[_vm._v(" Invoice contact "),_c('span',{staticClass:"vd-red"},[_vm._v("*")]),_vm._v(": ")])}]

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vms-invoices-overview__total-project vd-margin-bottom-50"},[_vm._m(0),(_vm.invoiceJobs.length === 0)?_c('div',{staticClass:"vms-invoices-overview__empty vd-text-align-center vd-padding-top-20"},[_c('img',{attrs:{"src":"/images/empty-states/empty-price-list.svg"}}),_c('p',{staticClass:"vd-padding-top-20"},[_vm._v(" This project has no price! Use the + button to add details ")])]):_c('div',{staticClass:"vms-invoices-overview__jobs"},[_vm._l((_vm.invoiceJobs),function(job,index){return _c('div',{key:index,staticClass:"vms-invoices-overview__job vd-padding-bottom-20 vd-padding-top-20 vd-border-light-grey"},[_c('div',{staticClass:"vms-invoices-overview__job--left"},[_vm._v(" "+_vm._s(job.name)+" "),(job.disbursement_type)?_c('span',{staticClass:"vd-margin-left-10 vd-padding-top-extra-small vd-padding-right-medium vd-padding-bottom-extra-small vd-padding-left-medium vd-border-radius-50 vd-background-light-blue vd-white"},[_vm._v(" "+_vm._s(job.disbursement_type)+" ")]):_vm._e()]),_c('div',{staticClass:"vms-invoices-overview__job--right"},[_vm._v(" "+_vm._s(_vm._f("moneyFormat")(job.price))+" "),(!_vm.canEditInvoiceJob(job.order))?_c('span',{attrs:{"id":("editInvoiceJob-" + (job.id) + "Span")},on:{"click":function($event){return _vm.handleEditJobBtn(job.id)}}},[_c('div',{staticClass:"vms-invoices-overview__edit-price vd-margin-left-extra-small vd-icon-pencil-circle"})]):_vm._e()])])}),_c('div',{class:[
        'vms-invoices-overview__job vms-invoices-overview__job--total vd-padding-bottom-20 vd-padding-top-20 vd-border-light-grey',
        _vm.isMediumScreen ? 'vms-invoices-overview__job--total__small' : '' ]},[_vm._m(1),_c('div',{staticClass:"vms-invoices-overview__job--right"},[_c('strong',[_vm._v(_vm._s(_vm._f("moneyFormat")(_vm.project.total_price)))])])]),_c('div',{class:[
        'vms-invoices-overview__job vms-invoices-overview__job--total vd-padding-bottom-20 vd-padding-top-20 vd-border-light-grey',
        _vm.isMediumScreen ? 'vms-invoices-overview__job--total__small' : '' ]},[_c('div',{staticClass:"vms-invoices-overview__job--left"},[_vm._v("Paid with credits")]),_c('div',{staticClass:"vms-invoices-overview__job--right"},[_vm._v(" "+_vm._s(_vm.invoicePaidWithCredits)+" ")])]),_c('div',{class:[
        'vms-invoices-overview__job vms-invoices-overview__job--total vd-padding-bottom-20 vd-padding-top-20 vd-border-light-grey',
        _vm.isMediumScreen ? 'vms-invoices-overview__job--total__small' : '' ]},[_c('div',{staticClass:"vms-invoices-overview__job--left"},[_vm._v("Total invoiced")]),_c('div',{staticClass:"vms-invoices-overview__job--right"},[_vm._v(" "+_vm._s(_vm._f("moneyFormat")(_vm.invoiceTotalInvoiced))+" ")])]),_c('div',{class:[
        'vms-invoices-overview__job vms-invoices-overview__job--total vd-padding-bottom-20 vd-padding-top-20 vd-border-light-grey',
        _vm.isMediumScreen ? 'vms-invoices-overview__job--total__small' : '' ]},[_vm._m(2),_c('div',{staticClass:"vms-invoices-overview__job--right vd-red"},[_c('strong',[_vm._v(_vm._s(_vm._f("moneyFormat")(_vm.invoiceRemainingToBeInvoiced)))])])])],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vms-invoices-overview__total-title vd-light-blue vd-h4 vd-padding-bottom-20 vd-border-light-grey"},[_c('strong',[_vm._v("Total project price")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vms-invoices-overview__job--left"},[_c('strong',[_vm._v("Total (excl. GST)")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vms-invoices-overview__job--left"},[_c('strong',[_vm._v("Amount remaining to be invoiced")])])}]

export { render, staticRenderFns }
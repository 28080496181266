<template>
  <div
    :class="[
      'vms-invoices-overview__invoice-terms vd-padding-bottom-20',
      isMobileScreen ? '' : 'vd-padding-top-20 vd-border-light-grey',
    ]"
  >
    <div
      :class="[
        isMobileScreen
          ? 'vd-margin-bottom-medium'
          : 'vms-invoices-overview__invoice-terms--left',
      ]"
    >
      Payment terms <span class="vd-red">*</span>:
    </div>

    <div
      :class="[
        'vms-invoices-overview__invoice-terms--select',
        isMobileScreen
          ? 'vms-invoices-overview__invoice-terms--select__mobile'
          : 'vms-invoices-overview__invoice-terms--right',
      ]"
    >
      <div class="vms-input__select">
        <select
          v-model="paymentTerm"
          class="vd-border-light-grey vd-background-transparent vd-padding-bottom-extra-small"
        >
          <option disabled>Payment Terms</option>
          <option
            v-for="(paymentTermLabel, index) in paymentTermsList"
            :key="index"
            :value="index"
          >
            {{ paymentTermLabel }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PaymentTermsConstants from '@configs/payment-terms'

export default {
  props: {
    defaultPaymentTerm: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      paymentTermsList: PaymentTermsConstants.paymentTerms,
      paymentTerm: this.defaultPaymentTerm,
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
    }),
  },

  watch: {
    paymentTerm(val) {
      this.$emit('update:payment-terms', val)
    },
  },
}
</script>

<template>
  <div
    v-if="showCreditsSection"
    :class="[
      isMobileScreen
        ? 'vd-margin-bottom-large'
        : 'vms-invoices-overview__credits vd-margin-bottom-50',
    ]"
  >
    <div
      :class="[
        isMobileScreen
          ? 'vd-light-blue vd-margin-bottom-medium vd-h4'
          : 'vms-invoices-overview__credits-title vd-light-blue vd-h4 vd-padding-bottom-20 vd-border-light-grey',
      ]"
    >
      <strong>Credits Available</strong>
    </div>

    <default-loader v-if="creditListLoading"></default-loader>

    <template v-else>
      <credits-mobile v-if="isMobileScreen"></credits-mobile>
      <credits-desktop v-else></credits-desktop>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VmsConstants from '@configs/vms-constants'
import DefaultLoader from '@components/loaders/default-loader'
import CreditsDesktop from '@views/project/invoices/project-invoices-overview/credits/project-invoices-overview-credits-desktop'
import CreditsMobile from '@views/project/invoices/project-invoices-overview/credits/project-invoices-overview-credits-mobile'

export default {
  components: {
    DefaultLoader,
    CreditsDesktop,
    CreditsMobile,
  },

  computed: {
    ...mapGetters({
      creditList: 'project/invoice/creditList',
      invoiceOrderDetail: 'project/invoice/invoiceOrderDetail',
      isProjectCancelled: 'project/isProjectCancelled',
      creditListLoading: 'project/invoice/creditListLoading',
      isMobileScreen: 'common/isMobileScreen',
    }),

    showCreditsSection() {
      return (
        !this.isProjectCancelled &&
        this.creditList.length > 0 &&
        this.invoiceOrderDetail.order_invoicing_term_id !==
          VmsConstants.orderInvoiceTerms
            .ORDER_INVOICING_TERM_ID_50_PERCENT_UPFRONT_AND_COMPLETION
      )
    },
  },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'vms-invoices-overview__invoice-terms vd-padding-bottom-20',
    _vm.isMobileScreen ? '' : 'vd-padding-top-20 vd-border-light-grey' ]},[_c('div',{class:[
      _vm.isMobileScreen
        ? 'vd-margin-bottom-medium'
        : 'vms-invoices-overview__invoice-terms--left' ]},[_vm._v(" Payment terms "),_c('span',{staticClass:"vd-red"},[_vm._v("*")]),_vm._v(": ")]),_c('div',{class:[
      'vms-invoices-overview__invoice-terms--select',
      _vm.isMobileScreen
        ? 'vms-invoices-overview__invoice-terms--select__mobile'
        : 'vms-invoices-overview__invoice-terms--right' ]},[_c('div',{staticClass:"vms-input__select"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.paymentTerm),expression:"paymentTerm"}],staticClass:"vd-border-light-grey vd-background-transparent vd-padding-bottom-extra-small",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.paymentTerm=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":""}},[_vm._v("Payment Terms")]),_vm._l((_vm.paymentTermsList),function(paymentTermLabel,index){return _c('option',{key:index,domProps:{"value":index}},[_vm._v(" "+_vm._s(paymentTermLabel)+" ")])})],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="vms-invoices-overview__invoice-terms">
    <div
      :class="[
        'vd-padding-bottom-20',
        isMobileScreen ? '' : 'vd-padding-top-20',
      ]"
    >
      <div
        :class="[
          isMobileScreen
            ? 'vd-margin-bottom-medium'
            : 'vms-invoices-overview__invoice-terms--left',
        ]"
      >
        Send invoice to <span class="vd-red">*</span>:
      </div>

      <div
        :class="[
          isMobileScreen ? '' : 'vms-invoices-overview__invoice-terms--right',
        ]"
      >
        <div
          :class="[
            'vms-invoices-overview__invoice-term-option',
            isMobileScreen
              ? 'vd-display-inline-block vd-margin-right-large'
              : '',
          ]"
        >
          <input
            id="invoiceRecipientClient"
            v-model="invoiceThirdParty"
            type="radio"
            value="client"
          />
          <label for="invoiceRecipientClient" class="vd-margin-left-small">
            Client
          </label>
        </div>

        <div
          :class="[
            'vms-invoices-overview__invoice-term-option',
            isMobileScreen ? 'vd-display-inline-block' : '',
          ]"
        >
          <input
            id="invoiceRecipientThirdParty"
            v-model="invoiceThirdParty"
            type="radio"
            value="third-party"
          />
          <label for="invoiceRecipientThirdParty" class="vd-margin-left-small">
            Third party
          </label>
        </div>
      </div>
    </div>

    <div
      v-if="isThirdPartySelected"
      :class="[
        'vms-invoices-overview__invoice-third-party vd-padding-bottom-20',
        isMobileScreen
          ? 'vd-padding-large vd-background-lightest-grey vd-border-radius-6'
          : '',
      ]"
    >
      <div
        :class="[
          isMobileScreen
            ? ''
            : 'vms-invoices-overview__invoice-terms--left vd-padding-top-30',
        ]"
      >
        Entity name <span class="vd-red">*</span>:
      </div>

      <div
        :class="[
          isMobileScreen
            ? 'vd-margin-bottom-large'
            : 'vms-invoices-overview__invoice-terms--right',
        ]"
      >
        <div
          :class="[
            'vms-invoices-overview__invoice-terms--entity-wrapper',
            isMobileScreen
              ? 'vms-invoices-overview__invoice-terms--entity-wrapper__mobile'
              : '',
          ]"
        >
          <input-component
            type="text"
            name="entityName"
            :value="entityNameValue"
            :container-custom-classes="[setInputValidationClass('entityName')]"
            @formInput="handleInputUpdate($event, 'entityName')"
          ></input-component>
        </div>
      </div>

      <div
        :class="[
          isMobileScreen
            ? ''
            : 'vms-invoices-overview__invoice-terms--left vd-padding-top-30',
        ]"
      >
        Entity contact name <span class="vd-red">*</span>:
      </div>

      <div
        :class="[
          isMobileScreen
            ? 'vd-margin-bottom-large'
            : 'vms-invoices-overview__invoice-terms--right',
        ]"
      >
        <div
          :class="[
            'vms-invoices-overview__invoice-terms--entity-wrapper',
            isMobileScreen
              ? 'vms-invoices-overview__invoice-terms--entity-wrapper__mobile'
              : '',
          ]"
        >
          <input-component
            type="text"
            name="entityContactName"
            :value="entityContactNameValue"
            :container-custom-classes="[
              setInputValidationClass('entityContactName'),
            ]"
            @formInput="handleInputUpdate($event, 'entityContactName')"
          ></input-component>
        </div>
      </div>

      <div
        :class="[
          isMobileScreen
            ? ''
            : 'vms-invoices-overview__invoice-terms--left vd-padding-top-30',
        ]"
      >
        Entity contact email <span class="vd-red">*</span>:
      </div>

      <div
        :class="[
          isMobileScreen
            ? 'vd-margin-bottom-large'
            : 'vms-invoices-overview__invoice-terms--right',
        ]"
      >
        <div
          :class="[
            'vms-invoices-overview__invoice-terms--entity-wrapper',
            isMobileScreen
              ? 'vms-invoices-overview__invoice-terms--entity-wrapper__mobile'
              : '',
          ]"
        >
          <input-component
            type="text"
            name="entityContactEmail"
            :value="entityContactEmailValue"
            :container-custom-classes="[
              setInputValidationClass('entityContactEmail'),
            ]"
            @formInput="handleInputUpdate($event, 'entityContactEmail')"
          ></input-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FormMixin from '@mixins/forms-mixin'
import InputComponent from '@components/form/form-input'
import { email, requiredIf } from 'vuelidate/lib/validators'

export default {
  components: {
    InputComponent,
  },

  mixins: [FormMixin],

  props: {
    defaultThirdPartyValue: {
      type: String,
      required: true,
      default: 'client',
    },

    entityNameValue: {
      type: String,
      required: false,
      default: '',
    },

    entityContactNameValue: {
      type: String,
      required: false,
      default: '',
    },

    entityContactEmailValue: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      invoiceThirdParty: this.defaultThirdPartyValue,
      entityName: this.entityNameValue,
      entityContactName: this.entityContactNameValue,
      entityContactEmail: this.entityContactEmailValue,
    }
  },

  validations: {
    entityName: {
      required: requiredIf(function () {
        return this.isThirdPartySelected
      }),
    },
    entityContactName: {
      required: requiredIf(function () {
        return this.isThirdPartySelected
      }),
    },
    entityContactEmail: {
      required: requiredIf(function () {
        return this.isThirdPartySelected
      }),
      email,
    },
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
    }),

    isThirdPartySelected() {
      return this.invoiceThirdParty === 'third-party'
    },
  },

  watch: {
    invoiceThirdParty(val) {
      this.$emit('update:invoice-third-party', val)
    },

    entityName(val) {
      this.$emit('update:entity-name', val)
    },

    entityContactName(val) {
      this.$emit('update:entity-contact-name', val)
    },

    entityContactEmail(val) {
      this.$emit('update:entity-contact-email', val)
    },
  },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'vms-invoices-overview__invoice-terms vd-padding-bottom-20',
    _vm.isMobileScreen ? '' : 'vd-padding-top-20 vd-border-light-grey' ]},[_c('div',{class:[
      _vm.isMobileScreen
        ? 'vd-margin-bottom-medium'
        : 'vms-invoices-overview__invoice-terms--left' ]},[_vm._v(" Invoice terms "),_c('span',{staticClass:"vd-red"},[_vm._v("*")]),_vm._v(": ")]),_c('div',{class:[
      _vm.isMobileScreen ? '' : 'vms-invoices-overview__invoice-terms--right' ]},[(
        _vm.project.office.client_term_id ===
        _vm.vmsConstants.clientTerms.OFFICE_CLIENT_TERM_FORTNIGHTLY
      )?_c('div',{staticClass:"vms-invoices-overview__invoice-term-option",attrs:{"id":"invoiceTermFortnightlyDiv"},on:{"click":_vm.handleInvoiceFortnightlyClick}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.invoicingTerms),expression:"invoicingTerms"}],attrs:{"id":"invoiceTermFortnightly","type":"radio"},domProps:{"value":_vm.vmsConstants.orderInvoiceTerms.ORDER_INVOICING_TERM_ID_FORTNIGHTLY,"checked":_vm._q(_vm.invoicingTerms,_vm.vmsConstants.orderInvoiceTerms.ORDER_INVOICING_TERM_ID_FORTNIGHTLY)},on:{"change":function($event){_vm.invoicingTerms=_vm.vmsConstants.orderInvoiceTerms.ORDER_INVOICING_TERM_ID_FORTNIGHTLY}}}),_c('label',{staticClass:"vd-margin-left-small",attrs:{"for":"invoiceTermFortnightly"}},[_vm._v(" Fortnightly invoicing ")])]):_vm._e(),_c('div',{staticClass:"vms-invoices-overview__invoice-term-option"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.invoicingTerms),expression:"invoicingTerms"}],attrs:{"id":"invoiceTerm100PercentOnOrderSubmission","type":"radio"},domProps:{"value":_vm.vmsConstants.orderInvoiceTerms
            .ORDER_INVOICING_TERM_ID_100_PERCENT_ON_ORDER_SUBMISSION,"checked":_vm._q(_vm.invoicingTerms,_vm.vmsConstants.orderInvoiceTerms
            .ORDER_INVOICING_TERM_ID_100_PERCENT_ON_ORDER_SUBMISSION)},on:{"change":function($event){_vm.invoicingTerms=_vm.vmsConstants.orderInvoiceTerms
            .ORDER_INVOICING_TERM_ID_100_PERCENT_ON_ORDER_SUBMISSION}}}),_c('label',{staticClass:"vd-margin-left-small",attrs:{"for":"invoiceTerm100PercentOnOrderSubmission"}},[_vm._v(" 100% on order submission ")])]),(!_vm.creditApplied)?_c('div',{staticClass:"vms-invoices-overview__invoice-term-option"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.invoicingTerms),expression:"invoicingTerms"}],attrs:{"id":"invoiceTerm50PercentUpfrontAndCompletion","type":"radio"},domProps:{"value":_vm.vmsConstants.orderInvoiceTerms
            .ORDER_INVOICING_TERM_ID_50_PERCENT_UPFRONT_AND_COMPLETION,"checked":_vm._q(_vm.invoicingTerms,_vm.vmsConstants.orderInvoiceTerms
            .ORDER_INVOICING_TERM_ID_50_PERCENT_UPFRONT_AND_COMPLETION)},on:{"change":function($event){_vm.invoicingTerms=_vm.vmsConstants.orderInvoiceTerms
            .ORDER_INVOICING_TERM_ID_50_PERCENT_UPFRONT_AND_COMPLETION}}}),_c('label',{staticClass:"vd-margin-left-small",attrs:{"for":"invoiceTerm50PercentUpfrontAndCompletion"}},[_vm._v(" 50% upfront 50% completion ")])]):_vm._e(),_c('div',{staticClass:"vms-invoices-overview__invoice-term-option"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.invoicingTerms),expression:"invoicingTerms"}],attrs:{"id":"invoiceTermAfterKeyDeliverable","type":"radio"},domProps:{"value":_vm.vmsConstants.orderInvoiceTerms
            .ORDER_INVOICING_TERM_ID_AFTER_KEY_DELIVERABLE,"checked":_vm._q(_vm.invoicingTerms,_vm.vmsConstants.orderInvoiceTerms
            .ORDER_INVOICING_TERM_ID_AFTER_KEY_DELIVERABLE)},on:{"change":function($event){_vm.invoicingTerms=_vm.vmsConstants.orderInvoiceTerms
            .ORDER_INVOICING_TERM_ID_AFTER_KEY_DELIVERABLE}}}),_c('label',{staticClass:"vd-margin-left-small",attrs:{"for":"invoiceTermAfterKeyDeliverable"}},[_vm._v(" After key deliverable ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }
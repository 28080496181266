<template>
  <div class="vms-invoices-overview__result-hits vd-border-light-grey">
    <div class="vms-invoices-overview__no-results vd-h4 vd-padding-10 vd-black">
      No results found for:
      <strong>
        <em>{{ query }}</em>
      </strong>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    query: {
      type: String,
      required: true,
      default: '',
    },
  },
}
</script>

<template>
  <div class="vd-margin-bottom-large">
    <div class="vd-light-blue vd-margin-bottom-medium vd-h4">
      <strong>Total Project Price</strong>
    </div>

    <div
      v-for="(job, index) in invoiceJobs"
      :key="index"
      class="vd-background-white vd-border-width-1 vd-border-light-grey vd-border-radius-6 vd-box-shadow vd-padding-medium vd-margin-bottom-medium"
    >
      <div class="vd-margin-bottom-small">
        <div class="row no-gutters">
          <div class="col-10">
            <div class="vd-darker-grey vd-margin-bottom-extra-small">
              {{ job.name }}
            </div>

            <div class="vd-light-blue">
              {{ job.price | moneyFormat }}
            </div>

            <div v-if="job.disbursement_type" class="vd-margin-top-small">
              <span
                class="vd-display-inline-block vd-white vd-background-light-blue vd-padding-top-extra-small vd-padding-bottom-extra-small vd-padding-left-medium vd-padding-right-medium vd-border-radius-50"
              >
                {{ job.disbursement_type }}
              </span>
            </div>
          </div>
          <div class="col-2 vd-text-align-right">
            <span
              v-if="!canEditInvoiceJob(job.order)"
              :id="`editInvoiceJob-${job.id}Span`"
              @click="handleEditJobBtn(job.id)"
            >
              <div class="vd-icon-pencil-circle"></div>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="vd-background-white vd-border-width-1 vd-border-light-grey vd-border-radius-6 vd-box-shadow vd-padding-medium vd-margin-bottom-medium"
    >
      <div class="vd-margin-bottom-small">
        <div class=""><strong>Total (excl. GST)</strong></div>
        <div>
          <strong>{{ project.total_price | moneyFormat }}</strong>
        </div>
      </div>
      <div class="vd-margin-bottom-small">
        <div class="">Paid with credits</div>
        <div>{{ invoicePaidWithCredits }}</div>
      </div>
      <div class="vd-margin-bottom-small">
        <div class="">Total invoiced</div>
        <div>{{ invoiceTotalInvoiced | moneyFormat }}</div>
      </div>
      <div class="vd-margin-bottom-small">
        <div class=""><strong>Amount remaining to be invoiced</strong></div>
        <div class="vd-red">
          <strong>{{ invoiceRemainingToBeInvoiced | moneyFormat }}</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FiltersMixin from '@mixins/filters-mixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [FiltersMixin],
  props: {
    canEditInvoiceJob: {
      type: Function,
      required: true,
    },

    handleEditJobBtn: {
      type: Function,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      invoiceJobs: 'project/invoice/invoiceJobs',
      invoicePaidWithCredits: 'project/invoice/invoicePaidWithCredits',
      invoiceTotalInvoiced: 'project/invoice/invoiceTotalInvoiced',
      invoiceRemainingToBeInvoiced:
        'project/invoice/invoiceRemainingToBeInvoiced',
    }),
  },
}
</script>

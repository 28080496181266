<template>
  <div class="vms-invoices-overview__result-hits vd-border-light-grey">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="vms-invoices-overview__invoice-terms--option-contact vd-border-light-grey vd-padding-10 vd-position-relative"
    >
      <div @click="handleClickContact(item)">
        <div class="vd-h5 vd-dark-grey">{{ item.full_name }}</div>
        <div class="vd-h4 vd-black">{{ item.email | truncateString(27) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isEmpty } from 'lodash'
import FiltersMixin from '@mixins/filters-mixin'

export default {
  mixins: [FiltersMixin],

  props: {
    items: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
    },
  },

  computed: {
    ...mapGetters({
      invoiceOrderDetail: 'project/invoice/invoiceOrderDetail',
    }),
  },

  methods: {
    ...mapActions({
      addInvoiceContact: 'project/invoice/addInvoiceContact',
    }),

    handleClickContact(item) {
      const officeAccountantId = !isEmpty(
        this.invoiceOrderDetail.office_accountant
      )
        ? this.invoiceOrderDetail.office_accountant.id
        : 0

      const payload = {
        id: parseInt(item.user_id),
        full_name: item.full_name,
        email: item.email,
        is_office_accountant:
          officeAccountantId.toString() === item.user_id.toString(),
      }

      this.addInvoiceContact(payload)

      // This is to reset and clear result of the algolia search.
      if (document.getElementById('clearRefinementsInvoiceContact')) {
        document.getElementById('clearRefinementsInvoiceContact').click()
      }

      this.$emit('update:search-off-focus')
    },
  },
}
</script>

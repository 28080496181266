<template>
  <div>
    <total-price-mobile
      v-if="isMobileScreen"
      :handle-edit-job-btn="handleEditJobBtn"
      :can-edit-invoice-job="canEditInvoiceJob"
    ></total-price-mobile>

    <total-price-desktop
      v-else
      :handle-edit-job-btn="handleEditJobBtn"
      :can-edit-invoice-job="canEditInvoiceJob"
    ></total-price-desktop>
  </div>
</template>

<script>
import VmsConstants from '@configs/vms-constants'
import { mapActions, mapGetters } from 'vuex'
import { includes } from 'lodash'
import TotalPriceDesktop from '@views/project/invoices/project-invoices-overview/total-price/project-invoices-overview-total-price-desktop'
import TotalPriceMobile from '@views/project/invoices/project-invoices-overview/total-price/project-invoices-overview-total-price-mobile'

export default {
  components: {
    TotalPriceDesktop,
    TotalPriceMobile,
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
    }),
  },

  methods: {
    ...mapActions({
      toggleEditJobShowModal: 'project/invoice/toggleEditJobShowModal',
      updatedSelectedJobId: 'project/invoice/updateSelectedInvoiceJobId',
    }),

    handleEditJobBtn(id) {
      this.updatedSelectedJobId(id)
      this.toggleEditJobShowModal()
    },

    canEditInvoiceJob(order) {
      return (
        order.order_invoice_status_id ===
          VmsConstants.orderInvoiceStatuses.ORDER_INVOICED ||
        (order.order_invoicing_term_id ===
          VmsConstants.orderInvoiceTerms
            .ORDER_INVOICING_TERM_ID_50_PERCENT_UPFRONT_AND_COMPLETION &&
          includes(
            [
              VmsConstants.orderInvoiceStatuses.ORDER_UNDER_100_INVOICING,
              VmsConstants.orderInvoiceStatuses.ORDER_50_INVOICED,
            ],
            order.order_invoice_status_id
          ))
      )
    },
  },
}
</script>

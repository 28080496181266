<template>
  <div>
    <div
      :class="[
        'vms-invoices-overview__accountant-contact vd-border-light-grey vd-padding-extra-small vd-border-radius-6',
        isMobileScreen
          ? 'vms-invoices-overview__accountant-contact--mobile vd-border-width-1'
          : '',
        isSearchOnFocus || hasValueInSearch
          ? 'vms-invoices-overview__accountant-contact--filled'
          : '',
      ]"
    >
      <div
        id="searchContactIconForInvoiceInput"
        class="vd-icon-search vms-invoices-overview__search-icon vd-inline-block vd-margin-left-extra-small"
        @click="handleSearchIconClick"
      ></div>
      <input
        id="searchContactForInvoiceInput"
        ref="searchContactForInvoiceInput"
        v-click-outside="handleBlur"
        :value="currentRefinement"
        class="vms-invoices-overview__contact-search vd-inline-block vd-border-transparent vd-margin-0 vd-padding-left-10 vd-padding-top-0 vd-padding-bottom-0 vd-padding-right-0 vd-border-0"
        @input="$emit('update:current-refinement', $event)"
        @focus="$emit('update:search-on-focus')"
      />
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import ClickOutside from 'vue-click-outside'
import { mapGetters } from 'vuex'

export default {
  directives: {
    ClickOutside,
  },

  props: {
    currentRefinement: {
      type: String,
      required: false,
      default: '',
    },
    isSearchOnFocus: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
    }),

    hasValueInSearch() {
      return !isEmpty(this.currentRefinement)
    },
  },

  methods: {
    handleSearchIconClick() {
      this.$refs.searchContactForInvoiceInput.focus()
    },

    // v-click-outside can only take methods instead of emit.
    handleBlur() {
      this.$emit('update:search-off-focus')
    },
  },
}
</script>
